import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='relative overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='flex items-center justify-between pt-6 -m-2'>
              <div className='w-auto p-2'>
                <div className='flex flex-wrap items-center'>
                  <div className='w-auto'>
                    <a className='relative z-10 inline-block' href='#'>
                      <img
                        src='images/coloerd.svg'
                        alt=''
                        className=''
                      />
                    </a>
                    <span className='inline-block ml-2 text-white font-medium text-2xl tracking-tighter'>
                      Slick
                    </span>
                  </div>
                </div>
              </div>

              <div className='w-auto p-2'>
                <div className='flex flex-wrap items-center'>
                  <div className='w-auto hidden lg:block'>
                    <ul className='flex items-center mr-12'>
                      <li className='mr-12 text-white font-medium hover:text-opacity-90 tracking-tighter'>
                        <a href='#'>Home</a>
                      </li>
                      <li className='mr-12 text-white font-medium hover:text-opacity-90 tracking-tighter'>
                        <a href='/#finops'>FinOps Platform</a>
                      </li>
                      <li className='mr-12 text-white font-medium hover:text-opacity-90 tracking-tighter'>
                        <a href='/#features'>Features</a>
                      </li>
                      <li className='text-white font-medium hover:text-opacity-90 tracking-tighter'>
                        <a href='https://slickco.readme.io'>Developers</a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-auto hidden lg:block'>
                    <div className='inline-block'>
                      <a
                        className='inline-block px-8 py-4 text-white hover:text-black tracking-tighter hover:bg-green-400 border-2 border-white focus:border-green-400 focus:border-opacity-40 hover:border-green-400 focus:ring-4 focus:ring-green-400 focus:ring-opacity-40 rounded-full transition duration-300'
                        href='/contact?utm_source=website&utm_medium=button&utm_campaign=website'
                      >
                        Get started
                      </a>
                    </div>
                  </div>

                  {/* <div className='w-auto lg:hidden'>
                    <a className='relative z-10 inline-block' href='#'>
                      <svg
                        className='navbar-burger text-green-500'
                        width={51}
                        height={51}
                        viewBox='0 0 56 56'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          width={56}
                          height={56}
                          rx={28}
                          fill='currentColor'
                        />
                        <path
                          d='M37 32H19M37 24H19'
                          stroke='black'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          
          {/* <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
            <nav className='relative z-10 px-9 pt-8 h-full overflow-y-auto'>
              <div className='flex flex-wrap justify-between h-full'>
                <div className='w-full'>
                  <div className='flex items-center justify-between -m-2'>
                    <div className='w-auto p-2'>
                      <a className='inline-block' href='#'>
                        <img src='nightsable-assets/logos/logo.svg' alt='' />
                      </a>
                    </div>
                    <div className='w-auto p-2'>
                      <a
                        className='navbar-burger inline-block text-white'
                        href='#'
                      >
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M6 18L18 6M6 6L18 18'
                            stroke='currentColor'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col justify-center py-16 w-full'>
                  <ul>
                    <li className='mb-8 text-white font-medium hover:text-opacity-90 tracking-tighter'>
                      <a href='#'>Home</a>
                    </li>
                    <li className='mb-8 text-white font-medium hover:text-opacity-90 tracking-tighter'>
                      <a href='#'>About us</a>
                    </li>
                    <li className='mb-8 text-white font-medium hover:text-opacity-90 tracking-tighter'>
                      <a href='#'>Wallet</a>
                    </li>
                    <li className='text-white font-medium hover:text-opacity-90 tracking-tighter'>
                      <a href='#'>Blog</a>
                    </li>
                  </ul>
                </div>
                <div className='flex flex-col justify-end w-full pb-8'>
                  <a
                    className='inline-block px-8 py-4 text-center text-white hover:text-black tracking-tighter hover:bg-green-400 border-2 border-white focus:border-green-400 focus:border-opacity-40 hover:border-green-400 focus:ring-4 focus:ring-green-400 focus:ring-opacity-40 rounded-full transition duration-300'
                    href='#'
                  >
                    Get in touch
                  </a>
                </div>
              </div>
            </nav>
          </div> */}

          <div className='relative pt-20 lg:pb-40 pb-20'>
            <div className='relative z-10 container px-4 mx-auto'>
              
              <div className='flex flex-wrap items-end -m-8'>
                
                <div className='relative z-10 w-full md:w-1/2 lg:w-7/12 p-8'>
                  <div className='md:max-w-xl'>
                    <span
                      className='inline-block mb-2.5 text-md text-green-400 font-medium tracking-tighter'

                    >
                      Embedded AR and AP
                    </span>
                    <h1
                      className='mb-8 text-7xl lg:text-8xl xl:text-10xl text-white tracking-tighter'

                    >
                      Start your fintech revolution
                    </h1>
                    <p className='mb-10 text-lg text-white md:max-w-xs'>
                      Slick enables Fintech and SaaS companies to launch best in
                      class AR and AP workflows for their customers.
                    </p>

                  {/* <div id='cta' className='my-8'>
                    <div className='inline-block mr-4'>
                    <a
                      className='inline-block px-8 py-4 tracking-tighter bg-green-400 hover:bg-green-500 text-black focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 rounded-full transition duration-300'
                      href='#'
                    >
                      Start now
                    </a>
                    </div>

                    <div className='inline-block'>
                      <a
                        className='inline-block px-8 py-4 text-white hover:text-black tracking-tighter hover:bg-green-400 border-2 border-white focus:border-green-400 focus:border-opacity-40 hover:border-green-400 focus:ring-4 focus:ring-green-400 focus:ring-opacity-40 rounded-full transition duration-300'
                        href='#'
                      >
                        Developers
                      </a>

                    </div>
                    </div> */}

                  </div>

                
                </div>

                <div className='w-full md:w-1/4 lg:w-5/12 p-8'>
                
                
                <div className=' min-w-max w-full p-4'>
                  <div className='hover:border-opacity-60 hover:shadow-sm hover:shadow-gray-800 transition-all relative pt-16 px-10 pb-8 h-full bg-gradient-radial-dark border border-gray-800  border-opacity-30 rounded-5xl'>
                    <div className='relative z-10'>
                      <h3 className='mb-6 text-3xl text-white tracking-3xl'>
                        Slick Platform
                      </h3>
                      <p className='text-white my-4'>
                        ✅ Accounts Receivable 
                      </p>

                      <p className='text-white my-4'>
                        ✅ Accounts Payable
                      </p>

                      <p className='text-white my-4'>
                        ✅ Payments
                      </p>

                      <p className='text-white my-4'>
                        ✅ Developer Friendly
                      </p>

                      <div id='cta'>
                    <div className='inline-block mr-4'>
                    <a
                      className='inline-block px-8 py-4 tracking-tighter bg-green-400 hover:bg-green-500 text-black border-2 border-green-400 focus:border-green-500 focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 rounded-full transition duration-300'
                      href='/contact?utm_source=website&utm_medium=button&utm_campaign=website'
                    >
                      Start now
                    </a>
                    </div>

                    <div className='inline-block'>
                      <a
                        className='inline-block px-8 py-4 text-white hover:text-black tracking-tighter hover:bg-green-400 border-2 border-white focus:border-green-400 focus:border-opacity-40 hover:border-green-400 focus:ring-4 focus:ring-green-400 focus:ring-opacity-40 rounded-full transition duration-300'
                        href='https://slickco.readme.io?utm_source=website&utm_medium=button&utm_campaign=website'
                      >
                        Developers
                      </a>

                    </div>
                    </div>
                    <p className='text-white my-8 text-opacity-60'>
                        Sign up or jump straight to our developer docs
                      </p>

                    </div>
                    
                    <img
                      className='absolute right-0 bottom-0'
                      src='nightsable-assets/images/cards/card-blur.svg'
                      alt=''
                    />
                  </div>
                </div>


                  <div className='relative max-w-max mx-auto md:mr-0'>
                    <img
                      className='absolute -top-16 -left-16'
                      // src='nightsable-assets/images/headers/star.svg'
                      alt=''
                    />
                    <img
                      className='absolute top-56 -left-44'
                      // src='nightsable-assets/images/headers/star2.svg'
                      alt=''
                    />
                    <div className='absolute bottom-10 -right-40'>
                      <img
                        className='relative -bottom-4'
                        // src='nightsable-assets/images/headers/star5.svg'
                        alt=''
                      />
                      <img
                        // src='nightsable-assets/images/headers/star3.svg'
                        alt=''
                      />
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          {/* <img
            className='absolute top-0 left-0'
            src='images/Favicon-Colored.png'
            alt=''
          /> */}
          <img
            className='absolute top-24 right-0'
            src='nightsable-assets/images/headers/lines.svg'
            alt=''
          />
        </section>


        <section id='finops' className='relative overflow-hidden py-12'>
          <div className='container px-4 mx-auto'>
            
            <div  className='mb-20 md:max-w-xl text-center mx-auto'>
              <span className='inline-block mb-4 text-md text-green-400 font-medium tracking-tighter'>
                FinOps Platform
              </span>
            </div>

            <div className='hover:border-opacity-60 hover:shadow-sm hover:shadow-gray-800 transition-all relative mb-10 py-20 px-16 overflow-hidden border border-gray-900 border-opacity-30 rounded-5xl'>
              <div className='max-w-6xl mx-auto'>
                <div className='relative z-10 flex flex-wrap items-center -m-8 justify-center'>
                  <div className='w-full md:w-1/2 p-8 justify-center'>
                    <div className='max-w-md mx-auto text-center'>
                      <h2 className='mb-6 text-7xl text-white tracking-tighter-xl'>
                        Own the flow of funds
                      </h2>
                      <p className='text-white text-opacity-60'>
                        Enable real time reporting on income customer
                        transactions and funds outstanding.{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <img
                className='absolute bottom-0 left-0'
                src='nightsable-assets/images/features/bg-gray.png'
                alt=''
              />
            </div>

            <div className='flex flex-wrap -m-5'>
              <div className='w-full md:w-1/2 p-5'>
                <div className='hover:border-opacity-60 hover:shadow-sm hover:shadow-gray-800 transition-all relative pt-14 pb-16 h-full bg-gradient-radial-dark overflow-hidden border border-gray-900 border-opacity-30 rounded-5xl px-16'>
                  <div className='relative z-10 max-w-sm text-center mx-auto'>
                    <h2 className='mb-6 text-7xl text-white tracking-tighter'>
                      Capture payments revenue
                    </h2>
                    <p className='text-white text-opacity-60'>
                      Leverage embedded invoicing and accounts payable to
                      underpin expansion revenue.
                    </p>
                  </div>
                  <img
                    className='absolute bottom-0 right-0'
                    src='nightsable-assets/images/features/bg-gray-2.png'
                    alt=''
                  />
                </div>
              </div>

              <div className='w-full md:w-1/2 p-5'>
                <div className='hover:border-opacity-60 hover:shadow-sm hover:shadow-gray-800 transition-all relative px-16 pt-14 pb-16 h-full bg-gradient-radial-dark overflow-hidden border border-gray-900 border-opacity-30 rounded-5xl'>
                  <div className='mb-14 max-w-sm mx-auto'></div>
                  <div className='relative z-10 max-w-sm text-center mx-auto'>
                    <h2 className='mb-6 text-7xl text-white tracking-tighter'>
                      Launch quickly and securely
                    </h2>
                    <p className='text-white text-opacity-60'>
                      Embeddable flows, secure API infrastructure and a flexible
                      component library.
                    </p>
                  </div>
                  <img
                    className='absolute bottom-0 right-0'
                    src='nightsable-assets/images/features/bg-gray-2.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className='py-24 bg-blueGray-950'>
          <div className='container px-4 mx-auto'>
            <div className='text-center'>

            <div className='mb-20 md:max-w-xl text-center mx-auto'>
              <span className='inline-block mb-4 text-md text-green-400 font-medium tracking-tighter'>
                Features
              </span>
              </div>
              <div className='flex flex-wrap -m-4'>
                <div className='w-full md:w-1/2 lg:w-1/3 p-4'>
                  <div className='hover:border-opacity-60 hover:shadow-sm hover:shadow-gray-800 transition-all relative pt-24 px-10 pb-36 h-full bg-gradient-radial-dark border border-gray-800 border-opacity-30 rounded-5xl'>
                    <div className='relative z-10'>
                      <h3 className='mb-6 text-3xl text-white tracking-3xl'>
                        Accounts Receivable
                      </h3>
                      <p className='text-white text-opacity-60'>
                        Allow your customers to create, manage and send beautifully
                        styled invoices
                      </p>
                    <a href='https://slickco.readme.io/reference/get_all_invoices_invoices_get?utm_source=website&utm_medium=button&utm_campaign=website' > 
                    <span className='inline-block mt-4'> Learn More </span>
                    </a>
                    </div>
                    <img
                      className='absolute right-0 bottom-0'
                      src='nightsable-assets/images/cards/card-blur.svg'
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 p-4'>
                  <div className='hover:border-opacity-60 hover:shadow-sm hover:shadow-gray-800 transition-all relative pt-24 px-10 pb-36 h-full bg-gradient-radial-dark border border-gray-800 border-opacity-30 rounded-5xl'>
                    <div className='relative z-10'>
                      <h3 className='mb-6 text-3xl text-white tracking-3xl'>
                        Built for Developers
                      </h3>
                      <p className='text-white text-opacity-60'>
                        Open API, live documentation and code snippets to help
                        you move quickly
                      </p>
                    <a href='https://slickco.readme.io?utm_source=website&utm_medium=button&utm_campaign=website'>
                    <span className='inline-block mt-4'> Learn More </span>
                    </a>
                    </div>
                    <img
                      className='absolute right-0 bottom-0'
                      src='nightsable-assets/images/cards/card-blur.svg'
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 p-4'>
                  <div className='hover:border-opacity-60 hover:shadow-sm hover:shadow-gray-800 transition-all relative pt-24 px-10 pb-36 h-full bg-gradient-radial-dark border border-gray-800 border-opacity-30 rounded-5xl'>
                    <div className='relative z-10'>
                      <h3 className='mb-6 text-3xl text-white tracking-3xl'>
                        Accounts Payable
                      </h3>
                      <p className='text-white text-opacity-60'>
                        Enable your customers to log, manage and pay their bills
                        within your platform.
                      </p>
                    <a href='https://slickco.readme.io?utm_source=website&utm_medium=button&utm_campaign=website'> 
                    <span className='inline-block mt-4'> Learn More </span>
                    </a>
                    </div>
                    <img
                      className='absolute right-0 bottom-0'
                      src='nightsable-assets/images/cards/card-blur.svg'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='py-16 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap items-center justify-between -m-8'>
              <div className='w-auto p-8'>
                <a className='relative z-10 inline-block' href='#'>
                  <span className='inline-block mb-4 text-md text-green-400 font-medium tracking-tighter'>
                    withslick.co
                  </span>
                  </a>
              </div>
              <div className='w-auto p-8'>
                <ul className='flex flex-wrap -m-5'>
                  <li className='p-5'>
                    <a
                      className='inline-block text-lg font-medium text-gray-300 hover:text-white transition duration-300'
                      href='#'
                    >
                      Contact
                    </a>
                  </li>
                  <li className='p-5'>
                    <a
                      className='inline-block text-lg font-medium text-gray-300 hover:text-white transition duration-300'
                      href='/#finops'
                    >
                      FinOps Platform
                    </a>
                  </li>
                  <li className='p-5'>
                    <a
                      className='inline-block text-lg font-medium text-gray-300 hover:text-white transition duration-300'
                      href='/#features'
                    >
                      Features
                    </a>
                  </li>
                  <li className='p-5'>
                    <a
                      className='inline-block text-lg font-medium text-gray-300 hover:text-white transition duration-300'
                      href='privacy'
                    >
                      Privacy
                    </a>
                  </li>
                  <li className='p-5'>
                    <a
                      className='inline-block text-lg font-medium text-gray-300 hover:text-white transition duration-300'
                      href='terms'
                    >
                      Terms
                    </a>
                  </li>
                </ul>
              </div>
              <div className='w-auto p-8'>
                <a
                  className='inline-block px-8 py-5 text-black rounded-full bg-green-400 hover:bg-green-500 focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 transition duration-300'
                  href='/contact'
                  style={{}}
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

