import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Terms() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className='pt-20 pb-24 md:pb-28 overflow-hidden'>
        <div className='container px-4 mx-auto'>
          <div className='flex flex-wrap -m-8'>
            <div className='w-full md:w-1/2 p-8'>
              <div className='md:max-w-md'>
                <span className='inline-block mb-4 text-md text-green-400 font-medium tracking-tighter'>
                <a href='/' className='inline-block mb-6'>
                  withslick.co
                  </a>
                </span>
                <h1 className='text-6xl md:text-7xl text-white tracking-tighter-xl'>
                  Terms
                </h1>
              </div>
            </div>
            <div className='w-full md:w-2/3 p-8 mb-10 text-lg text-gray-300'>

            <section class="my-8">
  <h1 class="text-2xl font-bold mb-4">Governing Law</h1>
  <p class="text-gray-600">The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
</section>
<section class="my-8">
  <h1 class="text-2xl font-bold mb-4">Disputes Resolution</h1>
  <p class="text-gray-600">If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>
</section>
<section class="my-8">
  <h1 class="text-2xl font-bold mb-4">For European Union (EU) Users</h1>
  <p class="text-gray-600">If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.</p>
</section>
<section class="my-8">
  <h1 class="text-2xl font-bold mb-4">United States Legal Compliance</h1>
  <p class="text-gray-600">You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>
</section>
<section class="my-8">
  <h1 class="text-2xl font-bold mb-4">Severability and Waiver</h1>
  <h2 class="text-lg font-bold mb-2">Severability</h2>
  <p class="text-gray-600">If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
  <h2 class="text-lg font-bold mb-2">Waiver</h2>
  <p class="text-gray-600">Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</p>
</section>
<section class="my-8">
  <h1 class="text-2xl font-bold mb-4">Translation Interpretation</h1>
  <p class="text-gray-600">These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.</p>
</section>
<section class="my-8">
  <h1 class="text-2xl font-bold mb-4">Changes to These Terms and Conditions</h1>
  <p class="text-gray-600">We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
  <p class="text-gray-600">By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
</section>
<section class="my-8">
  <h1 class="text-2xl font-bold mb-4">Contact Us</h1>
  <p class="text-gray-600">If you have any questions about these Terms and Conditions, You can contact us:</p>
  <p class="text-gray-600">By email: hq@slickco.io</p>
</section>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

