import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Privacy() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className='pt-20 pb-24 md:pb-28 overflow-hidden'>
        <div className='container px-4 mx-auto'>
          <div className='flex flex-wrap -m-8'>
            <div className='w-full md:w-1/2 p-8'>
              <div className='md:max-w-md'>
                <span className='inline-block mb-4 text-md text-green-400 font-medium tracking-tighter'>
                <a href='/' className='inline-block mb-6'>
                  withslick.co
                  </a>
                </span>
                <h1 className='text-6xl md:text-7xl text-white tracking-tighter-xl'>
                  Privacy Policy
                </h1>
              </div>
            </div>
            <div className='w-full md:w-2/3 p-8 mb-10 text-lg text-gray-300'>
              
            <div className='p-4'>

      <p className='text-gray-700 mb-4'>
        This privacy policy will explain how our organization (“we”, “us”, “Slick”, "SlickCo") uses the personal data we collect from you when you use our website (“Site”) or application (“App”) to perform our service (“Service”).
      </p>
      <h2 className='text-lg font-bold mb-2'>Type of information we collect, generate and store</h2>
      <p className='text-gray-700 mb-4'>
        We collect, process and store various types of information regarding your visit and usage of our Site and App and when we provide our Service.
      </p>
      <h3 className='text-base font-bold mb-2'>Personally Identifiable Information</h3>
      <p className='text-gray-700 mb-4'>
        When you contact us through our Site or use our App we ask for information such as first and last name and your email address (“Personal Information”). If you use our App, you will also provide a password. Your password is never visible to us and your email address and password are stored encrypted (as is all of your data in the App). When using our Site or App, you may fill out a form to contact us or request information.
      </p>
      <h3 className='text-base font-bold mb-2'>Third-party services</h3>
      <p className='text-gray-700 mb-4'>
        If we provide signing up using a third party service (like Facebook, LinkedIn or Google), Slick receives the information you marked as publicly accessible at that particular service. In most cases this means your name, email address and public profile picture. We do not receive any more information and never have access to your passwords.
      </p>
      <h3 className='text-base font-bold mb-2'>Contact information</h3>
      <p className='text-gray-700 mb-4'>
        You can also contact Slick through interaction with the Slick support team, by subscribing to content Slick provides or through a form on our website. The contact information you provide can include your name and email address.
      </p>
      <h3 className='text-base font-bold mb-2'>Technical data</h3>
      <p className='text-gray-700 mb-4'>
        Our website and app collects data from your interactions with our Service. This includes but is not limited by pages you visit, time spent using our Service, your location, device and browser information and language preferences.
      </p>
      <h3 className='text-base font-bold mb-2'>Payment information</h3>
      <p className='text-gray-700 mb-4'>
        If Slick is to process payments through the website or app, it will handle the process through a third party specialist provider and will not store personal financial information unless it is required to perform the services. For example, Slick will never store credit card data but can store financial transaction data if displaying that information to client is required in the app services.
      </p>
      <h2 className='text-lg font-bold mb-2'>Where this information is stored</h2>
      <p className='text-gray-700 mb-4'>
        Slick operates internationally and transfers and stores all data in the United States. If you are located outside of the United States and choose to make use of our Service or provide information to Slick, you acknowledge and understand that your information will be transferred, processed and stored in the United States, as it is necessary to provide our Service. The United States may have privacy and data protection laws that differ from, and are potentially less protective than, the laws of your country. You agree to this risk when you share your information with us.
      </p>
      <h2 className='text-lg font-bold mb-2'>Usage of this information</h2>
      <p className='text-gray-700 mb-4'>
        We use the information we collect for the following purposes:
      </p>
      <ul className='list-disc list-inside mb-4'>
        <li className='text-gray-700'>Provide our Service</li>
        <li className='text-gray-700'>Develop our services</li>
        <li className='text-gray-700'>Transactional and Marketing Communication</li>
      </ul>
      <p className='text-gray-700 mb-4'>
        We use the information you provided to communicate about general or specific subjects in regards to Slick and the services we provide. We use this information for transactional communication like sending emails or notifications about specific services we perform for you. We can also use this information to send marketing communication like product updates or information that we feel may be of interest to the recipient. You are always in control of what you decide to receive from Slick. All our marketing email communication comes with opt-out links.
      </p>
      <h2 className='text-lg font-bold mb-2'>Sharing of information & use of cookies</h2>
      <p className='text-gray-700 mb-4'>
        We do not share your information or data generated by providing this information with third parties except in the situations outlined below. Slick also uses cookie technologies to collect additional website usage data and to improve our services. Learn more about cookies here. We also use 3rd party cookies. Examples of services we may be using are outlined below.
      </p>
      <ul className='list-disc list-inside mb-4'>
        <li className='text-gray-700'>Track and report services usage</li>
        <li className='text-gray-700'>Facilitate communication with you</li>
      </ul>
      <p className='text-gray-700 mb-4'>
        Slick uses third party products and cookies to track usage and generate reports to help us improve our services. These include, but are not limited to:
      </p>
      <ul className='list-disc list-inside mb-4'>
        <li className='text-gray-700'>Google Analytics</li>
        <li className='text-gray-700'>SendGrid</li>
        <li className='text-gray-700'>Bubble</li>
        <li className='text-gray-700'>Readme</li>
      </ul>
      <p className='text-gray-700 mb-4'>
        Slick uses third party products to facilitate communication with you. These include but are limited to:
      </p>
      <ul className='list-disc list-inside mb-4'>
        <li className='text-gray-700'>SendGrid for marketing and transactional emails</li>
      </ul>
      <p className='text-gray-700 mb-4'>
        We may preserve or disclose information about you to comply with a law, regulation, legal process, or governmental request; to assert legal rights or defend against legal claims; or to prevent, detect, or investigate illegal activity, fraud, abuse, violations of our terms, or threats to the security of the Services or the physical safety of any person.
      </p>
      <p className='text-gray-700 mb-4'>
        We may share non-personal information that is aggregated or de-identified so that it cannot reasonably be used to identify an individual. We may disclose such information publicly and to third parties. If we are involved in a merger, acquisition, or sale of assets, we will continue to take measures to protect the confidentiality of personal information and give affected users notice before transferring any personal information to a new entity.
      </p>
      <h2 className='text-lg font-bold mb-2'>Data retention & Your rights to access and control your data</h2>
      <p className='text-gray-700 mb-4'>
        We keep your information for as long as we provide the services to you, improve existing or develop new services that require your information, subject to any legal obligations to further retain said information.
      </p>
      <p className='text-gray-700 mb-4'>
        Information associated with your person will generally be kept until it is no longer necessary to provide our Service, until you ask us to delete it or connected service history is deleted by us. At any time, you can decide to delete information you explicitly shared with us, and we will erase it completely. The simplest approach is to reach out to your Slick contact person and request the deletion of all your information. Non-identifying information is set to expire after a given period of time. In some limited cases, some information must be kept indefinitely due to legal obligations.
      </p>
      <ul className='list-disc list-inside mb-4'>
        <li className='text-gray-700'>App usage log data is retained 14 days after your last activity</li>
        <li className='text-gray-700'>User account data is retained until the account is deleted</li>
        <li className='text-gray-700'>Service history data is retained until deleted</li>
      </ul>
      <p className='text-gray-700 mb-4'>
        Information that Slick shares with third parties to perform or improve its services is subject to varying retention rates but under our control.
      </p>
      <ul className='list-disc list-inside mb-4'>
        <li className='text-gray-700'>Data shared with Google Analytics is retained 26 months after your last visit</li>
        <li className='text-gray-700'>Website and app usage data shared with SendGrid is retained indefinitely or until we decide to delete it.</li>
      </ul>
      <h2 className='text-lg font-bold mb-2'>Information security</h2>
      <p className='text-gray-700 mb-4'>
        We follow industry-standard best practices to protect the data we collect, generate and maintain. We use TLS to encrypt traffic between client side and server side and all data is stored encrypted at rest. No method of transmission over the internet or digital data storage is fully secure and we do not guarantee or warrant the security of information you share with us and are not responsible for the theft, destruction, loss or inadvertent disclosure of your information or data. User passwords are stored salted and encrypted . We encourage our users to choose complex passwords when using our services.
      </p>
      <h2 className='text-lg font-bold mb-2'>Changes to This Policy</h2>
      <p className='text-gray-700 mb-4'>
        Slick can modify this privacy policy at any time. We will give you an opportunity to review the revised policy before deciding if you would like to continue to use our services.
      </p>
      <h2 className='text-lg font-bold mb-2'>Data Removal Requests</h2>
      <p className='text-gray-700 mb-4'>
        To contact our team, please email <a href='mailto:hq@slickco.io' className='text-green-400 underline'>hq@slickco.io</a>.
      </p>
    </div>

            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

