import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Widget } from '@typeform/embed-react'

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

const TypeFormComponent = () => {
  return <Widget id="cyZJ5cWg" style={{ width: '80%', height: 500 }} className="my-form" />
}

export default function Terms() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className='pt-20 pb-24 md:pb-28 overflow-hidden'>
        <div className='container px-4 mx-auto'>
          <div className='flex flex-wrap -m-8 justify-center'>
            <div className='w-full md:w-1/2 p-8'>
              <div className='md:max-w-md'>
                <a href='/' className='inline-block mb-6'>
                <span className='inline-block mb-4 text-md text-green-400 font-medium tracking-tighter'>
                  withslick.co
                </span>
                </a>
                <h1 className='text-6xl md:text-7xl text-white tracking-tighter-xl'>
                  Contact us 
                </h1>
              </div>
              <p className='text-white my-8 text-opacity-60'>
                        Fill out your details below and we'll be in touch shortly.
                      </p>
            </div>
            
            <div className='w-full md:w-1/2 p-8'>


            </div>


            < TypeFormComponent />
            
            {/* <div data-tf-widget="cyZJ5cWg" data-tf-opacity="100" data-tf-iframe-props="title=Contact Form for Invoicing and Accounts Payable Needs" data-tf-transitive-search-params data-tf-medium="snippet" 
            style="width:100%;height:500px;"
            >

            </div>
            <script src="//embed.typeform.com/next/embed.js"></script> */}

          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

